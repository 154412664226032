import { React } from 'lib'
import TriggersPage from './guidance/triggers'

function Triggers(props) {
  return (
    <TriggersPage {...props}/>
  )
}

export default Triggers
